import { Model } from '@cloudfun/core'
import { main, Store as MainStore, State as MainState } from './main'

import configuration from './configuration'
import role from './role'
import user from './user'
import actionLog from './action-log'
import bannerPosition from './banner-position'
import banner from './banner'
import picture from './picture'
import template from './template'
import category from './category'
import newsCategory from './news-category'
import news from './news'
import country from './country'
import brand from './brand'
import tag from './tag'
import attribute from './attribute'
import attributeValues from './attribute-values'
import warehouse from './warehouse'
import supplier from './supplier'

import member from './member'
import memberAccountBook from './member-account-book'
import memberAccountBookItem from './member-account-book-item'
import memberGrade from './member-grade'
import ban from './ban'
import replyTemplate from './reply-template'
import customerContact from './customer-contact'
import order from './order'
import orderItem from './order-item'
import payment from './payment'
import pictureAlbum from './picture-album'
import product from './product'
import productItem from './product-item'
import productItemSize from './product-item-size'
import activity from './activity'
import activityProduct from './activity-product'
import activityRule from './activity-rule'
import marketingRule from './marketing-rule'
import coupon from './coupon'
import shoppingCart from './shopping-cart'
import purchaseBill from './purchase-bill'
import purchaseBillItem from './purchase-bill-item'
import purchaseOrder from './purchase-order'
import purchaseOrderItem from './purchase-order-item'
import adjustInventoryBill from './adjust-inventory-bill'
import adjustInventoryBillItem from './adjust-inventory-bill-item'
import requisitionMemo from './requisition-memo'
import requisitionMemoItem from './requisition-memo-item'
import inventory from './inventory'
import inventoryRecord from './inventory-record'
import faq from './faq'

const model = new Model('CatsApartment', {
  main,
  configuration,
  role,
  user,
  actionLog,
  bannerPosition,
  banner,
  supplier,
  picture,
  template,
  brand,
  tag,
  attribute,
  category,
  newsCategory,
  news,
  country,
  warehouse,
  member,
  memberAccountBook,
  memberAccountBookItem,
  memberGrade,
  ban,
  replyTemplate,
  customerContact,
  order,
  orderItem,
  payment,
  pictureAlbum,
  product,
  productItem,
  productItemSize,
  activity,
  activityProduct,
  activityRule,
  marketingRule,
  coupon,
  shoppingCart,
  purchaseBill,
  purchaseBillItem,
  purchaseOrder,
  purchaseOrderItem,
  adjustInventoryBill,
  adjustInventoryBillItem,
  requisitionMemo,
  requisitionMemoItem,
  inventory,
  inventoryRecord,
  faq,
  attributeValues,
})

const backendServer = process.env.VUE_APP_BACKEND_URL
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true)
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`)
model.onLogin = (data) => model.clients.unauthorized.post('System/Login', {
  account: data.account,
  password: data.password,
  captchaToken: data.captchaToken,
  captcha: data.captcha
})
model.onLogout = () => model.clients.authorized.post('System/Logout')
model.onReloadUser = () => model.clients.authorized.post('System/CurrentUser')
model.onReloadEnums = () => model.clients.unauthorized.get('System/Enumerations')
model.onLog = (message) => model.clients.unauthorized.post('System/Log', message)

export type State = { main: MainState };
export type Store = MainStore<Pick<State, 'main'>>;

export default model
