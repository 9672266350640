import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "儀錶板"
    },
    {
      icon: "fa-users",
      title: "會員管理",
      subNodes: [
        {
          icon: "fa-user",
          to: "member",
          title: "會員資訊"
        },
        {
          icon: "fa-sitemap",
          to: "member-grade",
          title: "會員等級管理"
        },
        {
          icon: "fa-ban",
          to: "blacklist",
          title: "黑名單管理"
        }
      ]
    },
    {
      icon: "fa-archive",
      to: "order",
      title: "訂單管理"
    },
    {
      icon: "fa-dollar-sign",
      to: "payment",
      title: "帳務管理"
    },
    {
      icon: "fa-boxes",
      title: "倉儲管理",
      subNodes: [
        {
          icon: "ActivityIcon",
          to: "inventory-record",
          title: "庫存異動記錄"
        },
        {
          icon: "fa-bars",
          to: "inventory",
          title: "現有庫存查詢"
        },
        {
          icon: "fa-exchange-alt",
          to: "requisition-memo",
          title: "庫存調撥"
        },
        {
          icon: "fa-sliders-h",
          to: "adjust-inventory-bill",
          title: "庫存盤點"
        },
        {
          icon: "fa-truck-loading",
          to: "purchase-bill",
          title: "進貨資訊"
        },
        {
          icon: "fa-shopping-cart",
          to: "purchase-order",
          title: "採購資訊"
        },
        {
          icon: "fa-people-carry",
          to: "supplier",
          title: "供應商資訊"
        },
        {
          icon: "fa-warehouse",
          to: "warehouse",
          title: "倉庫資訊"
        },
      ]
    },
    {
      icon: "fa-box",
      title: "產品管理",
      subNodes: [
        {
          icon: "PackageIcon",
          to: "product",
          title: "產品資訊"
        },
        {
          icon: "fa-sitemap",
          to: "category",
          title: "類別資訊"
        },
        {
          icon: "fa-copyright",
          to: "brand",
          title: "品牌管理"
        },
        {
          icon: "fa-globe",
          to: "country",
          title: "國家資訊"
        },
        {
          icon: "fa-puzzle-piece",
          to: "attribute-values",
          title: "片數管理"
        },

      ]
    },
    {
      icon: "fa-tag",
      to: "tag",
      title: "標籤管理"
    },
    {
      icon: "fa-fire",
      title: "優惠活動",
      subNodes: [
        {
          icon: "SettingsIcon",
          to: "activity-rule",
          title: "活動規則"
        },
        {
          icon: "fa-fire",
          to: "activity",
          title: "活動資訊"
        },
        {
          icon: "PackageIcon",
          to: "activity-product",
          title: "活動產品"
        },
      ]
    },
    {
      icon: "fa-ticket-alt",
      to: "coupon",
      title: "優惠券管理"
    },
    {
      icon: "fa-newspaper",
      title: "最新消息",
      subNodes: [
        {
          icon: "fa-sitemap",
          to: "news-category",
          title: "類別資訊"
        },
        {
          icon: "fa-list-alt",
          to: "news",
          title: "最新消息"
        }
      ]
    },
    {
      icon: 'FlagIcon',
      to: 'banner',
      title: '廣告管理'
    },
    // {
    //   icon: "fa-user-friends",
    //   to: "member",
    //   title: "會員管理"
    // },
    {
      icon: "fa-pager",
      title: "內文管理",
      subNodes: [
        {
          icon: "fa-info-circle",
          to: "template/AboutUs",
          title: "關於我們"
        },
        {
          icon: "fa-dolly-flatbed",
          to: "template/ReturnPolicy",
          title: "退換貨政策"
        },
        {
          icon: "fa-user-shield",
          to: "template/PrivacyPolicy",
          title: "隱私權政策"
        }
      ]
    },
    {
      icon: "fa-question",
      to: "faq",
      title: "常見問題管理"
    },
    {
      icon: "fa-users",
      title: "用戶管理",
      subNodes: [
        {
          icon: "fa-magic",
          to: "user-wizard",
          title: "用戶註冊精靈"
        },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色資訊"
        },
        {
          icon: "fa-user",
          to: "user",
          title: "用戶資訊"
        }
      ]
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄"
    },
    {
      icon: "SettingsIcon",
      to: "configuration",
      title: "系統配置"
    },
    {
      icon: "fa-headset",
      title: "聯絡我們",
      subNodes: [
        {
          icon: "fa-inbox",
          to: "reply-template",
          title: "回應內容樣板"
        },
        {
          icon: "fa-headset",
          to: "customer-contact",
          title: "聯絡我們回覆"
        },
      ]
    },
    // {
    //   icon: "fa-chart-bar",
    //   to: "report",
    //   title: "報表管理"
    // },
  ]
};

export default sitemap;
